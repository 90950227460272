import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import TitleUnderline from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import imageFiveDesktop from "./images/imagesDesktop/imageFive.webp"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"
import imageFiveMobile from "./images/imagesMobile/imageFive.webp"



const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Notificaciones y alertas personalizadas",
      description: "Envía mensajes personalizados basados en el comportamiento o eventos específicos del usuario, como recordatorios de citas, alertas de nuevos productos o carritos abandonados, manteniendo a los usuarios informados y comprometidos.",
      image: imageOneMobile,
      background: "#FBE6E5"
    },
    {
      title: "Onboarding de usuarios y agentes",
      description: "Utiliza chatbots con IA para implementar a los usuarios guías interactivas personalizadas, respondiendo instantáneamente a preguntas frecuentes, recopilando feedback inicial, y promoviendo la permanencia en la empresa.",
      image: imageTwoMobile,
      background: "#EEEAFB"
    },
    {
      title: "Aumenta la generación de Leads",
      description: "Utiliza bots con IA para recopilar información clave de los leads que permitan ser calificados según sus intenciones de compra. Gestiona toda la información desde un solo lugar e implementa estrategias de marketing y ventas que generen conversión.",
      image: imageThreeMobile,
      background: "#D3F7FA"
    },
    {
      title: "Análisis del sentimiento del cliente",
      description: "Analiza todas las interacciones de tus bots para capturar y entender las emociones de los clientes en tiempo real, identificando tendencias y sentimientos, lo que permite ajustar respuestas y estrategias de forma proactiva para mejorar la satisfacción.",
      image: imageFourMobile,
      background: "#EEEAFB"
    },
    {
      title: "Mensajes Interactivos",
      description: "Incorpora elementos como botones, listas, carruseles y formularios en las conversaciones para guiar y simplificar la experiencia del usuario, haciendo que la interacción sea más agradable, al tiempo que se aumenta la probabilidad de conversión y se fomenta una mayor interacción.",
      image: imageFiveMobile,
      background: "#FBE6E5"
    },
  ]

  return (
    <section className="container-experience-chatbots">
      <p className="container-experience-chatbots-title">
        La plataforma omnicanal de Beex ayuda a las empresas a brindar una  <TitleUnderline underline="gran experiencia" /> en servicio al cliente
      </p>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-chatbots-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-chatbots-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 26px 0px 0px" }}>
          <section style={{ padding: "0px 10px 0px 32px" }} >
            <p className="container-experience-chatbots-cardsOne-card-title">Notificaciones y alertas personalizadas</p>
            <p className="container-experience-chatbots-cardsOne-card-description">
              Envía mensajes personalizados basados en el comportamiento o eventos específicos del usuario, como recordatorios de citas, alertas de nuevos productos o carritos abandonados, manteniendo a los usuarios informados y comprometidos.
            </p>
          </section>
          <section>
            <img
              className="container-experience-chatbots-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-chatbots-cardsOne-column">
          {/* columna */}
          <section className="container-experience-chatbots-cardsOne-card" style={{ background: "#EEEAFB",  padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-chatbots-cardsOne-card-title">Onboarding de usuarios y agentes</p>
              <p className="container-experience-chatbots-cardsOne-card-description">
                Utiliza chatbots con IA para implementar a los usuarios guías interactivas personalizadas, respondiendo instantáneamente a preguntas frecuentes, recopilando feedback inicial, y promoviendo la permanencia en la empresa.
              </p>
            </section>
            <section>
              <img
                className="container-experience-chatbots-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
          {/* columna */}
          <section className="container-experience-chatbots-cardsOne-card" style={{ background: "#D3F7FA", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-chatbots-cardsOne-card-title">Aumenta la generación de Leads</p>
              <p className="container-experience-chatbots-cardsOne-card-description">
                Utiliza bots con IA para recopilar información clave de los leads que permitan ser calificados según sus intenciones de compra. Gestiona toda la información desde un solo lugar e implementa estrategias de marketing y ventas que generen conversión. </p>
            </section>
            <section>
              <img
                className="container-experience-chatbots-cardsOne-card-image"
                src={imageThreeDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-chatbots-cardsTwo">
        {/* columna */}
        <div className="container-experience-chatbots-cardsTwo-cards-column">
          <section className="container-experience-chatbots-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-chatbots-cardsTwo-card-title">Análisis del sentimiento del cliente</p>
              <p className="container-experience-chatbots-cardsTwo-card-description">
                Analiza todas las interacciones de tus bots para capturar y entender las emociones de los clientes en tiempo real, identificando tendencias y sentimientos, lo que permite ajustar respuestas y estrategias de forma proactiva para mejorar la satisfacción.
              </p>
            </section>
            <section>
              <img
                className="container-experience-chatbots-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        {/* columna */}
        <section className="container-experience-chatbots-cardsTwo-card" style={{ background: "#FBE6E5", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-chatbots-cardsTwo-card-title">Mensajes Interactivos</p>
            <p className="container-experience-chatbots-cardsTwo-card-description">
              Incorpora elementos como botones, listas, carruseles y formularios en las conversaciones para guiar y simplificar la experiencia del usuario, haciendo que la interacción sea más agradable, al tiempo que se aumenta la probabilidad de conversión y se fomenta una mayor interacción.</p>
          </section>
          <section>
            <img
              className="container-experience-chatbots-cardsTwo-card-image"
              src={imageFiveDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </div>

      <div className="container-experience-chatbots-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-chatbots-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-chatbots-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-chatbots-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-chatbots-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
