/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqi from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Prestamype from "@components/sharedComponents/customersLogos/logos/Prestamype.svg"
import Cafae from "@components/sharedComponents/customersLogos/logos/Cafae.svg"
import Ittsabus from "@components/sharedComponents/customersLogos/logos/IttsaBus.svg"
import Cobra from "@components/sharedComponents/customersLogos/logos/Cobra.svg"
import ClinicaAviva from "@components/sharedComponents/customersLogos/logos/ClinicaAviva.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Savar from "@components/sharedComponents/customersLogos/logos/Savar.svg"
import Cepeban from "@components/sharedComponents/customersLogos/logos/Cepeban.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/chatbots-ia-casos-de-uso.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/chatbots-leads.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/script-chatbot.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/mejor-chatbot.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/chatbot-atencion-cliente.jpg"

// logos testimonios
import CasaRosselloTes from "@components/sharedComponents/testimonies/images/logos/casaRossello.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"
import KontigoTes from "@components/sharedComponents/testimonies/images/logos/kontigo.svg"
import AvivaTes from "@components/sharedComponents/testimonies/images/logos/aviva.svg"
import CepebanTes from "@components/sharedComponents/testimonies/images/logos/cepeban.svg"

//foto testimonio desktop
import casaRosselloDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/casaRossello.png"
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"
import kontigoDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/kontigo.png"
import avivaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/aviva.png"
import cepebanDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cepeban.png"

//foto testimonio desktop
import casaRosselloMobile from "@components/sharedComponents/testimonies/images/fotosMobile/casaRossello.png"
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"
import kontigoMobile from "@components/sharedComponents/testimonies/images/fotosMobile/kontigo.png"
import avivaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/aviva.png"
import cepebanMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cepeban.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageChannels/pageChatbots/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageChannels/pageChatbots/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageChannels/pageChatbots/images/accordionLeft/imageThree.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageChatbots.jpg"

import { TitleUnderlineChatbotsOne, TitleUnderlineChatbotsTwo } from "@components/pageChannels/components/intro/titleUnderlineChatbots"
import imageStartChatbots from "@components/pageChannels/components/intro/images/imageStartChatbots.webp"

//image indrustias
import banca from "@components/pageChannels/pageChatbots/industries/images/banca.png"
import salud from "@components/pageChannels/pageChatbots/industries/images/salud.png"
import ecommerce from "@components/pageChannels/pageChatbots/industries/images/ecommerce.png"
import educacion from "@components/pageChannels/pageChatbots/industries/images/educacion.png"
import concesionaria from "@components/pageChannels/pageChatbots/industries/images/concesionaria.png"
import inmobiliaria from "@components/pageChannels/pageChatbots/industries/images/inmobiliaria.png"


const titleStart = (
  <p>
    Vende y fideliza mejor con conversaciones personalizadas, usando <TitleUnderlineChatbotsOne underline="chatbots" /> <TitleUnderlineChatbotsTwo underline="con IA" />
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Diseña y crea fácilmente chatbots con IA para mejorar tus procesos de atención al cliente, marketing y ventas de tu empresa. Brinda experiencias conversacionales que generen conversión y lealtad con tu organización.
        <br /><br />
        Personaliza tu atención para obtener una comunicación más cercana y efectiva, adaptando tu mensaje a las necesidades individuales de cada cliente.
      </p>
    ),
    image: imageStartChatbots,
    textbutton: "Empezar ahora",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqi,
    Auna,
    Astara,
    Prestamype,
    Cafae,
    Ittsabus,
    Cobra,
    ClinicaAviva,
    G4S,
    Savar,
    Cepeban
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqi, Auna, Astara, Prestamype, Cafae,],
    arrayOne: [Ittsabus, Cobra, ClinicaAviva, G4S, Savar, Cepeban]
  },
  articles: [
    {
      image: article1,
      title: "Chatbots IA: 6 casos de uso para atención al cliente",
      textbody:
        "Descubre cómo los chatbots IA pueden mejorar la atención del cliente con estos 6 casos de uso. ¡ENTRA AQUÍ y construye relaciones duraderas!",
      link: "https://beexcc.com/blog/chatbots-ia-casos-de-uso/",
      card: true,
    },
    {
      image: article2,
      title: "¿Cómo califican los chatbots a los leads para ganar clientes?",
      textbody:
        "Descubre cómo califican los chatbots a los leads de tu negocio y porque es importante si quieres saber cómo ganar clientes por internet.",
      link: "https://beexcc.com/blog/chatbots-leads/",
      card: true,
    },
    {
      image: article3,
      title: "6 pasos para crear un script para tu chatbot",
      textbody:
        "¿Tienes un chatbot o piensas implementar uno? Aquí te contamos cuál es la forma correcta que deben comunicarse los bots con tus clientes.",
      link: "https://beexcc.com/blog/script-chatbot/",
    },
    {
      image: article4,
      title: "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
      textbody:
        "Hoy vas a saber cómo elegir el mejor chatbot para tu negocio. De esta forma, la inversión que llegues a hacer no será en vano.",
      link: "https://beexcc.com/blog/mejor-chatbot/",
    },
    {
      image: article5,
      title: "Chatbot para atención al cliente: ¿Qué usos debes darle?",
      textbody:
        "¿Quieres usar chatbots para tu atención al cliente? En este artículo te explicamos qué usos le puedes dar en beneficio de tus consumidores.",
      link: "https://beexcc.com/blog/chatbot-atencion-cliente/",
    },
  ],
  contacts: {
    title: "Crea experiencias conversacionales automáticas en tus canales de atención",
    textbody: "Nuestros asesores estarán dispuestos para que puedas tener tu propio chatbot hoy mismo.",
    href: "/hablemos/",
    btn: "Hablar como un experto",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: CasaRosselloTes,
      title:
        "Con los chatbots de  Beex, hemos mejorado la prospección de nuestros leads",
      textbody:
        "Casa Rosselló, compañía con más de 150 años de historia en el sector acabados y construcción, prospecta de forma más óptima con WhatsApp API y livechat.",
      name: "Marlen Vega",
      post: "Jefa de Marketing de Casa Rosselló",
      link: "/casos-de-exito/casa-rossello/",
      imgDesktop: casaRosselloDesktop,
      imgMobile: casaRosselloMobile,
      metrics: [
        {
          metric: "+75%",
          descripcion: "aumento de leads digitales",
          color: "#FFB800",
        },
        {
          metric: "+65%",
          descripcion: "aumento de recompra",
          color: "#5831D8",
        },
      ],
    },
    {
      image: AstaraTes,
      title:
        "Vendemos 3x más al mes automatizando nuestro embudo de ventas con chatbots en WhatsApp",
      textbody:
        "Astara, concesionaria de automóviles con presencia en Latam, ha automatizado sus procesos de marketing y ventas para canal orgánico y de paga con WhatsApp API.",
      name: "Jonny Castillo",
      post: "Jefe Comercial de Astara",
      link: "/casos-de-exito/astara/",
      imgDesktop: astaraDesktop,
      imgMobile: astaraMobile,
      metrics: [
        {
          metric: "x3",
          descripcion: "aumentó sus ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "93%",
          descripcion: "redujo su esfuerzo para remarketing",
          color: "#5831D8",
        },
        {
          metric: "80%",
          descripcion: "mejoró contactabilidad con leads",
          color: "#FFB800",
        },
      ],
    },
    {
      image: KontigoTes,
      title:
        "Con  Beex, somos capaces de cerrar el 98% de las consultas en primer contacto",
      textbody:
        "Kontigo, fintech respaldada por Mastercard, automatiza su atención al cliente omnicanal, con chatbots por WhatsApp que brindan atención 24x7.",
      name: "Analucía Siguas Velásquez",
      post: "Gerente de Marketing y Producto en GMoney",
      link: "/casos-de-exito/kontigo/",
      imgDesktop: kontigoDesktop,
      imgMobile: kontigoMobile,
      metrics: [
        {
          metric: "98%",
          descripcion: "casos cerrados en primer contacto",
          color: "#FFB800",
        },
        {
          metric: "<1 minuto",
          descripcion: "tiempo de primera respuesta",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#FFB800",
        },
      ],
    },
    {
      image: AvivaTes,
      title:
        "Mejoramos el tiempo para agendar citas en +30%, automatizando procesos con chatbots por WhatsApp",
      textbody:
        "Aviva, parte del grupo Intercorp, clínica multiespecialidad con diferentes servicios en salud, ha logrado automatizar su proceso de citas médicas con chatbots en WhatsApp.",
      name: "Luis Ordinola",
      post: "Coordinador de mejora de procesos",
      link: "/casos-de-exito/clinica-aviva/",
      imgDesktop: avivaDesktop,
      imgMobile: avivaMobile,
      metrics: [
        {
          metric: "+30%",
          descripcion: "mejoró el tiempo de agenda de citas",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "ahora representa WhatsApp como canal para agendar citas",
          color: "#5831D8",
        },
      ],
    },
    {
      image: CepebanTes,
      title: "Con los chatbots de prospección, hemos mejorado la conversión de leads en +27%",
      textbody:
        "CEPEBAN, instituto especializado en banca y finanzas, ha aumentado a +1.5k la cantidad de leads mejor prospectados con chatbots por WhatsApp Business API.",
      name: "Alan De La Cruz",
      post: "Jefe de Sistemas de CEPEBAN",
      link: "/casos-de-exito/cepeban/",
      imgDesktop: cepebanDesktop,
      imgMobile: cepebanMobile,
      metrics: [
        {
          metric: "+27%",
          descripcion: "mejoró su tasa de conversión con sus leads",
          color: "#FFB800",
        },
        {
          metric: "+1.5k",
          descripcion: "leads prospectados al mes por chatbots",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#FFB800",
        },
      ],
    },
    {
      title: "conoce",
      metrics: [
      ],
    },
  ],
  accordionLeft: [
    {
      title: "Inteligencia Artificial",
      description: "<p>Utilizamos la IA para entender, procesar y responder a las consultas de los usuarios de manera natural y eficiente. Además, posibilita el aprendizaje continuo a partir de las interacciones, mejorando la precisión y relevancia de las respuestas con el tiempo.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Workflows y Automatizaciones",
      description: "<p>Implementa workflows automatizados a los chatbots para gestionar tareas repetitivas sin intervención humana, desde el seguimiento de un pedido hasta la programación de citas, mejorando la eficiencia de atención de tu empresa.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Integraciones vía APIs",
      description: "<p>Integra el bot con herramientas de terceros como tu CRM, ya sea HubSpot, Salesforce, Zoho u otros. Usa APIs escalables y permite acciones como la actualización inmediata de datos, notificar ante carritos abandonados o hacer un seguimiento de pedido en tiempo real.</p>",
      image: ImageAccordionLeftThree,
    },
  ],
  bannerVideo: {
    title: "Chatbot: Qué es, cómo funciona y cuáles son sus beneficios",
    description: "<p>En este video te contamos todo sobre chatbots. ¿Qué son? ¿Cómo funcionan? ¿Cuáles son sus beneficios según tu negocio? Todo con ejemplos de uso.  <br /> <br />Nuestros líderes de Beex, nuestra plataforma omnicanal con chatbots, Luismi y Gaby, nos cuentan claramente los 5 beneficios principales que tendrás al implementar chatbots en tu operación.</p>",
    image: imageVideo
  },
  industries: [
    {
      tab: "01. E-commerce",
      title: "Chatbots para E-commerce y Retail",
      text: (
        <p>
          <br />
          Facilita la experiencia del cliente mediante un autoservicio para la gestión de pedidos, seguimiento de envíos y resolución de consultas frecuentes. Optimiza la interacción digital con los usuarios, mejorando la satisfacción y fidelización del cliente.
          <br /> <br /> <br />
        </p>
      ),
      image: ecommerce,
      active: "container-industries-channels-navbar-menu-button-active",
      link: "/hablemos/",
      textLink: "Suma IA chatbots para E-commerce",
    },
    {
      tab: "02. Banca y finanzas",
      title: "Chatbots para Banca, Seguros, Financieras y más",
      text: (
        <p>
          <br />
          Ofrece un autoservicio integral para la gestión de cuentas, transferencias y consultas financieras. Mejora la accesibilidad y seguridad en las transacciones digitales, fortaleciendo la confianza del cliente con tu banco, seguro o fintech.
          <br /> <br /> <br />
        </p>
      ),
      image: banca,
      link: "/hablemos/",
      textLink: "Suma IA chatbots para Banca y Finanzas",
    },
    {
      tab: "03. Educación",
      title: "Chatbots para Universidades, Institutos y Colegios",
      text: (
        <p>
          <br />
          Proporciona una experiencia omnicanal para la gestión de matrículas, consultas académicas y seguimiento del progreso estudiantil. Incrementa la eficiencia administrativa y la comunicación entre instituciones educativas, profesores y alumnos.
          <br /> <br /><br />
        </p>
      ),
      image: educacion,
      link: "/hablemos/",
      textLink: "Suma IA chatbots para Eduación",
    },
    {
      tab: "04. Inmobiliarias",
      title: "Chatbots para Inmobiliarias, Bienes y Raíces",
      text: (
        <p>
          <br />
          Proporciona información sobre propiedades, programación de visitas y actualizaciones de estado. Facilita la comunicación entre agentes, propietarios e interesados, agilizando el proceso de venta o alquiler.
          <br /> <br /><br />
        </p>
      ),
      image: inmobiliaria,
      link: "/hablemos/",
      textLink: "Suma IA chatbots para Inmobiliarias",
    },
    {
      tab: "05. Salud",
      title: "Chatbots para Clínicas, Hospitales y Centros de Salud",
      text: (
        <p>
          <br />
          Implementa un autoservicio para la gestión de citas, consultas frecuentes y disponibilidad de médicos en tu clínica u hospital. Mejora la atención digital con el paciente y aumenta la credibilidad de tu institución al ofrecer una experiencia más conveniente y eficiente para los usuarios.
          <br /> <br /><br />
        </p>
      ),
      image: salud,
      link: "/hablemos/",
      textLink: "Suma IA chatbots para Salud",
    },
    {
      tab: "06. Concesionarias",
      title: "Chatbots para Concesionarias",
      text: (
        <p>
          <br />
          Automatiza la gestión de citas de prueba de vehículos, consultas sobre modelos y disponibilidad de stock. Optimiza la experiencia del cliente en línea, generando un mayor interés y confianza en la marca automotriz.
          <br /> <br /><br />
        </p>
      ),
      image: concesionaria,
      link: "/hablemos/",
      textLink: "Suma IA chatbots para Inmobiliarias",
    },

  ],
}

export default data
