import React from "react"

export const TitleUnderlineLeftOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
       width="249" height="8" viewBox="0 0 249 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.293 8C41.145 7.25735 3.45039 8.08646 0.710524 7.86265C-1.52632 7.56254 2.04835 2.27751 4.12466 1.49925C4.85244 1.21949 5.69794 1.17881 7.63512 1.30597C10.7068 1.54505 50.4242 1.07198 50.4242 1.07198C50.9486 1.23475 52.5219 0.685396 52.8109 0.883775C53.7206 1.34666 118.097 0.715913 123.587 0.69048C126.916 0.67522 124.283 0.99059 128.2 0.863424C136.548 0.609092 154.743 0.853252 161.068 0.563314C166.13 0.324241 167.222 0.314075 172.081 0.507368C177.047 0.680314 203.45 0.431066 206.468 0.0597408C207.207 -0.0419921 207.774 -0.00638251 207.774 0.11061C207.774 0.136044 226.386 0.222513 226.472 0.314073C226.611 0.431066 227.178 0.487015 227.702 0.405629C228.676 0.283549 248.487 -0.15899 248.797 0.181815C249.771 1.15845 247.042 5.31932 244.741 6.33157C242.386 7.41502 229.458 6.19932 219.065 6.85041L170.679 6.91654L160.308 7.32347C159.12 6.97249 154.55 7.85756 151.789 7.22682C150.622 6.97757 142.381 6.94196 140.562 7.20647C138.978 7.40994 96.8201 6.89618 92.4962 7.60323C84.7903 7.01318 53.5387 7.85757 46.293 8Z" fill="#FFA700"/>
      </svg>
  </span>
)

export const TitleUnderlineLeftTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
       width="268" height="8" viewBox="0 0 268 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49.8254 8C44.2846 7.25735 3.71368 8.08646 0.76474 7.86265C-1.64279 7.56254 2.20465 2.27751 4.43939 1.49925C5.2227 1.21949 6.13273 1.17881 8.21772 1.30597C11.5238 1.54505 54.2718 1.07198 54.2718 1.07198C54.8363 1.23475 56.5296 0.685396 56.8406 0.883775C57.8198 1.34666 127.108 0.715913 133.018 0.69048C136.6 0.67522 133.766 0.99059 137.982 0.863424C146.968 0.609092 166.55 0.853252 173.358 0.563314C178.807 0.324241 179.982 0.314075 185.212 0.507368C190.557 0.680314 218.975 0.431066 222.223 0.0597408C223.018 -0.0419921 223.628 -0.00638251 223.628 0.11061C223.628 0.136044 243.66 0.222513 243.753 0.314073C243.902 0.431066 244.513 0.487015 245.077 0.405629C246.126 0.283549 267.448 -0.15899 267.782 0.181815C268.83 1.15845 265.893 5.31932 263.416 6.33157C260.882 7.41502 246.966 6.19932 235.781 6.85041L183.703 6.91654L172.54 7.32347C171.262 6.97249 166.343 7.85756 163.371 7.22682C162.115 6.97757 153.246 6.94196 151.287 7.20647C149.582 7.40994 104.208 6.89618 99.5541 7.60323C91.2603 7.01318 57.6239 7.85757 49.8254 8Z" fill="#FFA700"/>
      </svg>
      
  </span>
)